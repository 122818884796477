// Include our external dependencies.
import * as Sentry from "@sentry/browser";
import offlineStorage from "store/dist/store.modern.min";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV,
  release: process.env.VERSION,
  ignoreErrors: ["SecurityError: The operation is insecure."],
  beforeSend(event) {
    if (event.exception) {
      // configure error event details.
      const details = { eventId: event.event_id };
      const user = offlineStorage.get("user");
      if (user) details.user = { email: user.email };

      // Show the Sentry dialog.
      Sentry.showReportDialog(details);
    }

    // Only send the HTTP request in Prod and Staging environments.
    return process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "staging"
      ? event
      : false;
  },
});
